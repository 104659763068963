import React from 'react';
import './App.scss';
import Navbar from './components/Navbar';

import Particles from 'react-particles-js'

import { Parallax } from 'react-parallax';

import { KeyboardArrowDown, Person, Mail } from '@material-ui/icons/';
import { IconButton } from '@material-ui/core';

import ScrollAnimation from 'react-animate-on-scroll';

import GradientCard from './components/GradientCard';
import ContactForm from './components/ContactForm';
import Refund from './components/Refund';
function App() {
  return (
    <div className="App" >
      <div className="banner" id="up">
        <Navbar />
        <Particles />
        <span className="header">
          <h1 className="main-tittle" >Gabros</h1>
          <h2 className="secondary-tittle"> Services</h2>
        </span>
        <IconButton className="icon-tittle" href="#aboutUs">
          <KeyboardArrowDown fontSize="large" />
        </IconButton>
      </div>

      {/* about us */}
      <div>
        <Parallax
          bgImage={require('./static/img/01.jpg')}
          bgImageAlt="background"
          strength={150}
          bgImageStyle={{ zIndex: -1, }}
        >
          <div className="aboutUs" id="aboutUs">
            <div className="left-part">

            </div>
            <div className="right-part">
              <div className="text-part">
                <ScrollAnimation duration={0.5} animateIn="fadeIn" animateOnce={true} offset={10}>
                  <h2>O nás - </h2>
                </ScrollAnimation>
                <ScrollAnimation duration={0.5} animateIn="fadeIn" animateOnce={true} offset={10}>
                  <h1>Kdo jsme?</h1>
                </ScrollAnimation>
                <ScrollAnimation duration={1} animateIn="fadeIn" animateOnce={true} offset={10}>
                  <p>Poskytujeme kompletní servis firemního PR a online reklamy. Od správy webových stránek, až po celkovou vizáž virtuální tváře vaší firmy, vždy je pro nás na prvním místě konstrukce logického řešení bez zbytečných komplikací. Ušít mediální kabát na míru je pro nás samozřejmostí, záleží totiž jen na vašich požadavcích a vzájemné domluvě.
                    <br />
                    <Refund />
                  </p>

                </ScrollAnimation>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      {/* our services */}
      <div className="ourServices" id="ourServices">
        <div className="left-part">

        </div>
        <div className="right-part">
          <div className="text-part">
            <ScrollAnimation offset={10} duration={0.5} animateIn="fadeIn" animateOnce={true}>
              <h2>Naše služby - </h2>
            </ScrollAnimation>
            <ScrollAnimation offset={10} duration={0.5} animateIn="fadeIn" animateOnce={true}>
              <h1>Proč je důležitá prezentace firmy?</h1>
            </ScrollAnimation>
            <ScrollAnimation offset={10} duration={1} animateIn="fadeIn" delay={250} animateOnce={true}>
              <p>Kvalitní a poutavá prezentace je jedním z nejdůležitějších bodů na cestě za prosperující společností.</p>
              <br />
              <p>Komunikace s potenciálními klienty prostřednictvím webových stránek, sociálních sítí, či vhodně zvoleným reklamním sdělením je v dnešní době nenahraditelnou součástí každé úspěšné firmy. Originalita designu i obsahu propagačních materiálů dokáže klíčově ovlivnit rozhodnutí zákazníka ve váš prospěch.</p>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      {/* our services - cards */}
      <div>
        <Parallax
          bgImage={require('./static/img/03.jpg')}
          bgImageAlt="background2"
          strength={300}
          bgImageStyle={{ zIndex: -1, }}
        >
          <div className="ourServices-cards">
            <div className="left-card-holder">

              <div className="card">
                <GradientCard
                  imagePath={require("./static/img/cards/website.jpg")}>
                  <h3>Vývoj webových stránek</h3>
                  <p>Naprogramujeme vám zajímavé a responzivní stránky přesně podle vašich představ. Za použití nejmodernějších technologií zvládneme vše, od fóra až po eshopy.</p>
                </GradientCard>
              </div>
              <br />
              <div className="card">
                <GradientCard
                  imagePath={require("./static/img/cards/social.jpg")}
                  gradient="linear-gradient(rgba(147,39,143, 0.5),rgba(0,255,255, 0.5))"
                >
                  <h3>Správa sociálních sítí</h3>
                  <p>Sociální sítě jsou dnes nedílnou součástí prezentace. Zařídíme váš každý profil tak, aby zaujmul vaše potencionální klienty a přiblížil jim vaši firmu na dosah kliknutí myši.</p>
                </GradientCard>
              </div>
              <br />
              <div className="card">
                <GradientCard
                  imagePath={require("./static/img/cards/writting.jpg")}
                  gradient="linear-gradient(rgba(0, 255, 255, 0.5),rgba(255, 229,59, 0.5))"
                >
                  <h3>Copywriting</h3>
                  <p>Umíme napsat poutavé články, texty k reklamám nebo na webové stránky. Zařídíme prvotřídní propagaci a zasadíme se o novou atraktivní image vaší společnosti.</p>
                </GradientCard>
              </div>

            </div>


            <div className="right-card-holder">

              <div className="card">
                <GradientCard
                  gradient="linear-gradient(rgba(255,37,37, 0.5),rgba(255,229,59, 0.5))"
                  right={true} imagePath={require("./static/img/cards/translate.jpg")}>
                  <h3>Anglický překlad</h3>
                  <p>Zařídíme vaše stránky nebo sociální sítě i v anglickém jazyce. Poskytneme taktéž i překlad vašich existujících stránek či profilů na sociálních sítích.</p>
                </GradientCard>
              </div>
              <br />
              <div className="card">
                <GradientCard
                  gradient="linear-gradient(rgba(255,0,97, 0.5),rgba(254,193,148, 0.5))"
                  right={true} imagePath={require("./static/img/cards/design.jpg")}>
                  <h3>Design</h3>
                  <p>Vytvoříme dechberoucí design vašich propagačních materiálů, či stránek, nebo navrhneme neotřelé a zapamatovatelné logo pro vaši společnost.</p>
                </GradientCard>
              </div>
              <br />
              <div className="card">
                <GradientCard
                  gradient="linear-gradient(rgba(252,0,97, 0.5),rgba(31,201,253,0.5))"
                  right={true} imagePath={require("./static/img/cards/photography.jpg")}>
                  <h3>Fotografie</h3>
                  <p>Stejně jako za klávesnicí to umíme i za objektivem. Vyfotíme portfolio vašich produktů a zázemí firmy pro nové příspěvky, nebo web.</p>
                </GradientCard>
              </div>

            </div>

          </div>
        </Parallax>
      </div>

      <div className="contacts" id="contacts">
        <div className="left-part">
          <div className="text-part">
            <ScrollAnimation offset={10} duration={0.5} animateIn="fadeIn" animateOnce={true}>
              <h2>Kontakt - </h2>
            </ScrollAnimation>
            <ScrollAnimation offset={10} duration={0.5} animateIn="fadeIn" animateOnce={true}>
              <h1>Napište nám</h1>
            </ScrollAnimation>
            <ScrollAnimation offset={10} duration={1} animateIn="fadeIn" delay={250} animateOnce={true}>
              <p>Zaujala vás naše nabídka? Napište nám a domluvíme se na další spolupráci.</p>
              <div className="contact-person">
                <Person fontSize="large" /> Jan Ševčík <br />
                <Mail fontSize="large" /> jan.sevcik@gabros.cz<br />
              </div>

            </ScrollAnimation>
          </div>
        </div>
        <div className="right-part">
          <ContactForm />
        </div>
      </div>



    </div>
  );
}
export default App;