import React, { Component } from 'react';

import './css/GradientCard.scss';


class GradientCard extends Component {

    render() {
        let gradient = !this.props.gradient ? "linear-gradient(rgba(163, 44, 223, 0.5),rgba(16, 107, 210, 0.5))" : this.props.gradient;
        let backgroundImg = `${gradient}, url(${this.props.imagePath})`
        let orientation = this.props.right ? "right-oprientation" : "left-oprientation";
        return (
            <div className="gradient-card">
                <div className={orientation}>
                    <div className="image"
                        style={{ backgroundImage: backgroundImg }}
                    >
                    </div>
                    <div className="card-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default GradientCard;
