import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Collapse } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

class Refund extends Component {
    constructor() {
        super();
        this.state = { dialogOpenned: false, collapseIn: false }
    }

    openDialog() {
        this.setState({ dialogOpenned: true })
    }
    closeDialog() {
        this.setState({ dialogOpenned: false })
    }
    collapseIn() {
        this.setState({ collapseIn: !this.state.collapseIn })
    }


    render() {
        let fullscreen = false;
        if (window.innerWidth < 600) {
            fullscreen = true;
        }

        return (
            <span>

                <Button variant="outlined" onClick={this.openDialog.bind(this)}>Garance vrácení peněz</Button>
                <Dialog
                    open={this.state.dialogOpenned}
                    onClose={this.closeDialog.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen={fullscreen}
                >
                    <DialogTitle id="alert-dialog-title">Garance vrácení peněz</DialogTitle>
                    <DialogContent>

                        <DialogContentText>Pevně si stojíme za kvalitou našich služeb a dokážeme ji garantovat. Jak? No přeci vrácením peněz.</DialogContentText>
                        <DialogContentText>Všem našim zákazníkům dáváme možnost zažádat o vrácení plné částky za naše služby, a to celé dva měsíce od provedení zakázky.</DialogContentText>
                        <DialogContentText>Není to dost? Pokud se vám na naší práci nebude cokoli pozdávat i déle než po dvou měsících, rádi provedeme opravu podle vašich představ. Kdykoli.</DialogContentText>
                        <DialogContentText>Hrajeme fér.</DialogContentText>
                        <DialogContentText>
                            <Button variant="contained" style={{ backgroundColor: "#2176FF", color: "white" }} onClick={this.collapseIn.bind(this)}>
                                {this.state.collapseIn ? "Méně" : "Více"}
                            </Button>
                        </DialogContentText>
                        <Collapse in={this.state.collapseIn}>
                            <DialogContentText>
                                V současné době sbíráme pozitivní reference a chceme se ujistit, že jsou s námi naši klienti opravdu spokojení. Pokora je podle nás totiž něco, co by nemělo chybět v žádném zaměstnání, natož v tom našem. Pomáháme totiž českým firmám růst.
                                </DialogContentText>
                        </Collapse>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog.bind(this)}>
                            Zavřít
                        </Button>

                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

export default Refund;